<template>
  <b-row>
    <b-col cols="12">
      <h2>Empresas</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <div class="col-12 mt-16">
      <datatable :items="items" :fields="fields">
        <template slot="filters">
          <b-col cols="3" class="my-3">
            <b-button
              variant="primary"
              class="btn-ghost"
              v-b-modal.modal-add-document-admin
            >
              Añadir nueva empresa
            </b-button>
          </b-col>
        </template>

        <template slot-scope="{ item }" slot="actions">
          <b-row class="actions-icons">
            <span
              title="ver"
              @click="show(item.uuid)"
              :id="'_show_button_' + item.uuid"
            >
              <i class="iconly-Light-Show"></i>
            </span>
            <span
              title="editar"
              @click="edit(item.uuid)"
              :id="'_show_editar' + item.uuid"
            >
              <i class="iconly-Light-Edit mr-10"></i>
            </span>
            <span
              title="eliminar"
              @click="showDeleteConfirmation(item.uuid)"
              :id="'_show_eliminar' + item.uuid"
            >
              <i class="iconly-Light-Delete mr-10"></i>
            </span>
          </b-row>

          <b-tooltip :target="'_show_button_' + item.uuid" triggers="hover">
            Ver
          </b-tooltip>

          <b-tooltip :target="'_show_editar' + item.uuid" triggers="hover">
            Editar
          </b-tooltip>

          <b-tooltip :target="'_show_eliminar' + item.uuid" triggers="hover">
            Eliminar
          </b-tooltip>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          ></b-pagination>
        </template>
      </datatable>
    </div>

    <!-- create document -->
    <b-modal
      size="xl"
      id="modal-add-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nueva empresa</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre de Fantasía">
              <b-form-input
                v-model="documentCreate.name"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Región">
              <b-form-select
                v-model="documentCreate.region"
                @change="changecity($event)"
                :options="reg"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Comuna">
              <b-form-select
                v-model="documentCreate.city_uuid"
                :options="locationtype"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="RUT">
              <imask-input
                class="form-control"
                :value="documentCreate.dni"
                v-imask="dniMask"
                :unmask="true"
                @complete="onCompleteDni"
                placeholder="00.000.000-00"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Razón social">
              <b-form-input
                v-model="documentCreate.razon_social"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Dirección">
              <b-form-input
                v-model="documentCreate.address"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <span class="hp-caption text-black-80 hp-text-color-dark-30 pr-8">
              Activo
            </span>

            <b-form-checkbox
              switch
              size="lg"
              v-model="documentCreate.active"
              value="true"
            ></b-form-checkbox>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="submitFile">
              Crear
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-document-admin')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Confirmar eliminación</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar esta reservacion?
          </p>
        </b-col>

        <b-col cols="6" class="pr-2">
          <b-button variant="danger" block @click="confirmDelete">
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col cols="6" class="pl-2">
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- edit document -->
    <b-modal
      size="xl"
      id="modal-edit-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-edit-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Editar empresas</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre de Fantasía">
              <b-form-input
                v-model="documentCreate.name"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Región">
              <b-form-select
                v-model="documentCreate.region"
                @change="changecity"
                :options="reg"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Comuna">
              <b-form-select
                v-model="documentCreate.city_uuid"
                :options="locationtype"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="RUT">
              <imask-input
                class="form-control"
                :value="documentCreate.dni"
                v-imask="dniMask"
                :unmask="true"
                @complete="onCompleteDni"
                placeholder="00.000.000-00"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Razón social">
              <b-form-input
                v-model="documentCreate.razon_social"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Dirección">
              <b-form-input
                v-model="documentCreate.address"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <span class="hp-caption text-black-80 hp-text-color-dark-30 pr-8">
              Activo
            </span>

            <b-form-checkbox
              switch
              size="lg"
              v-model="documentCreate.active"
              value="true"
            ></b-form-checkbox>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="submitEditFile">
              Editar
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-document-admin')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <b-modal
      size="xl"
      id="modal-view-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-view-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Ver detalle empresa</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre de Fantasía">
              <b-form-input
                v-model="documentCreate.name"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Región">
              <b-form-select
                v-model="documentCreate.region"
                @change="changecity"
                :options="reg"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Comuna">
              <b-form-select
                v-model="documentCreate.city_uuid"
                :options="locationtype"
                :disabled="true"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="RUT">
              <b-form-input
                v-model="documentCreate.dni"
                placeholder="00.000.000-00"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Razón social">
              <b-form-input
                v-model="documentCreate.razon_social"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Dirección">
              <b-form-input
                v-model="documentCreate.address"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <span class="hp-caption text-black-80 hp-text-color-dark-30 pr-8">
              Activo
            </span>

            <b-form-checkbox
              switch
              size="lg"
              v-model="documentCreate.active"
              value="true"
              :disabled="true"
            ></b-form-checkbox>
          </b-col>

          <b-col cols="12 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-view-document-admin')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
} from "bootstrap-vue";

import datatable from "@/layouts/components/datatable/Datatable.vue";
import sweet from "@/logic/functions/sweetAlert";
import visits from "@/logic/functions/visits";
import company from "@/logic/functions/company";
import Locations from "@/logic/functions/Locations";
import dni from "@/logic/functions/dni";
import validate_dni from "@/logic/functions/users/validate_dni";
import Regions from "@/logic/functions/regions";

import { IMaskComponent, IMaskDirective, useIMask } from "vue-imask";
import { DateTime } from "luxon";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, //this
    BFormFile,
    BPagination,
    datatable,
    "imask-input": IMaskComponent,
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      fields: [
        {
          key: "uuid",
          label: "Nombre",
          formatter: (value, key, item) => {
            return item.name;
          },
        },
        { key: "city_uuid.name", label: "Comuna" },
        { key: "dni", label: "RUT" },
        { key: "razon_social", label: "Razón social" },
        { key: "address", label: "Dirección" },
        { key: "actions", label: "Acciones" },
      ],
      Actions: [],
      items: [],
      loadNewUsers: [],
      codeText: "",
      codeActive: false,
      codeActiveClass: false,
      flags: {
        formUpload: false,
        fileUpload: false,
      },
      reg: [],
      locationtype: [],
      Departaments: [],
      DniType: [],
      createSales: {},
      documentCreate: {
        active: true,
        dni_type_uuid: 3,
      },
      dniMask: {
        mask: "00.000.000-{[*]}",
        lazy: false,
      },
      dniIsValid: false,
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
    };
  },
  methods: {
    onCompleteDni(e) {
      let dni = e.detail._value.replace(/\./g, "");
      if (validate_dni.validaRut(dni)) {
        this.dniIsValid = true;
        this.documentCreate.dni = e.detail._value;
        e.target.style.border = "none";
      } else {
        this.dniIsValid = false;
        e.target.style.border = "3px solid #FF0000";
      }
    },
    show(uuid) {
      this.documentCreate = this.items.find((item) => {
        return item.uuid == uuid;
      });

      this.$bvModal.show("modal-view-document-admin");
    },
    edit(uuid) {
      this.documentCreate = {};
      this.documentCreate = this.items.find((item) => {
        return item.uuid == uuid;
      });

      this.$bvModal.show("modal-edit-document-admin");
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deletes(this.itemToDelete);
        this.$bvModal.hide("modal-delete-confirmation");
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid;
      this.$bvModal.show("modal-delete-confirmation");
    },
    deletes(uuid) {
      let items = this.items;
      company
        .delete(uuid)
        .then((response) => {
          const index = items.findIndex((item) => item.uuid === uuid);
          if (~index) {
            items.splice(index, 1);
          }
          sweet.ToastMsg("", "success", "Empresa eliminada");
        })
        .catch((error) => {});
    },
    async documents(page = 0) {
      await company
        .list({
          params: {
            limit: 5,
            page: 0,
          },
        })
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {});
    },
    async submitFile() {
      await company
        .create({
          name: this.documentCreate.name,
          dni: this.documentCreate.dni,
          razon_social: this.documentCreate.razon_social,
          address: this.documentCreate.address,
          city_uuid: this.documentCreate.city_uuid,
          active: this.documentCreate.active,
        })
        .then((response) => {
          this.documents();
          this.$bvModal.hide("modal-add-document-admin");
          sweet.ToastMsg("", "success", "Empresa creada");
        })
        .catch((error) => {
          this.flags.fileUpload = false;
          sweet.ToastMsg("", "error", "Empresa no creada");
        });
    },
    async submitEditFile() {
      await company
        .update(this.documentCreate.uuid, {
          name: this.documentCreate.name,
          dni: this.documentCreate.dni,
          razon_social: this.documentCreate.razon_social,
          address: this.documentCreate.address,
          city_uuid: this.documentCreate.city_uuid,
          active: this.documentCreate.active,
        })
        .then((response) => {
          this.documents();
          this.$bvModal.hide("modal-add-document-admin");
          sweet.ToastMsg("", "success", "Empresa editada");
        })
        .catch((error) => {
          this.flags.fileUpload = false;
          sweet.ToastMsg("", "error", "Empresa no editada");
        });
    },
    async changecity(event) {
      this.locationtype = [];
      Locations.listbyId(event)
        .then((response) => {
          let data = response.data.data;

          Object.entries(data).forEach((entry) => {
            const [key, value] = entry;
            this.locationtype.push({ value: value.uuid, text: value.name });
          });
        })
        .catch((error) => {
          console.log(error);
          sweet.ToastMsg("", "error", "no se pudo crear el edificio");
        });
    },
    async loadregion() {
      await Regions.list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.reg.push({ value: value.id, text: value.name });
          });
        })
        .catch((error) => {});
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1;

      this.documents(page);
    },
    ListLocations() {
      Locations.list()
        .then((response) => {
          let data = response.data.data;

          Object.entries(data).forEach((entry) => {
            const [key, value] = entry;
            this.locationtype.push({ value: value.uuid, text: value.name });
          });
        })
        .catch((error) => {
          console.log(error);
          sweet.ToastMsg("", "error", "no se pudo crear el edificio");
        });
    },
    listDniType() {
      dni
        .list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.DniType.push({ value: value.uuid, text: value.name });
          });
        })
        .catch((error) => {});
    },
  },
  beforeMount() {},
  mounted() {
    this.loadregion();
    this.listDniType();
    this.ListLocations();
    this.documents();
  },
};
</script>
