<template>
  <b-row>
    <b-col cols="12">
      <h2>Sponsors</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <div class="col-12 mt-16">
      <datatable :items="items" :fields="fields">
        <template slot="filters">
          <b-col cols="3" class="my-3">
            <b-button
              variant="primary"
              class="btn-ghost"
              v-b-modal.modal-add-document-admin
            >
              Añadir nuevo sponsors
            </b-button>
          </b-col>
        </template>

        <template slot-scope="{ item }" slot="actions">
          <b-row class="actions-icons">
            <span
              title="ver"
              @click="show(item)"
              :id="'_show_button_' + item.uuid"
            >
              <i class="iconly-Light-Show"></i>
            </span>
            <span
              title="editar"
              @click="edit(item.uuid)"
              :id="'_show_editar' + item.uuid"
            >
              <i class="iconly-Light-Edit mr-10"></i>
            </span>
            <span
              title="eliminar"
              @click="showDeleteConfirmation(item.uuid)"
              :id="'_show_eliminar' + item.uuid"
            >
              <i class="iconly-Light-Delete mr-10"></i>
            </span>
            <!-- <span title="mostrar edificios" @click="showBuildings(  item.uuid  )"> <i class="hp-text-color-dark-0 iconly-Bold-Show"></i> </span> -->
          </b-row>

          <b-tooltip :target="'_show_button_' + item.uuid" triggers="hover">
            Ver
          </b-tooltip>

          <b-tooltip :target="'_show_editar' + item.uuid" triggers="hover">
            Editar
          </b-tooltip>

          <b-tooltip :target="'_show_eliminar' + item.uuid" triggers="hover">
            Eliminar
          </b-tooltip>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          ></b-pagination>
        </template>
      </datatable>
    </div>
    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Confirmar eliminación</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar este sponsor?
          </p>
        </b-col>

        <b-col cols="6" class="pr-2">
          <b-button variant="danger" block @click="confirmDelete">
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col cols="6" class="pl-2">
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- create -->
    <b-modal
      size="xl"
      id="modal-add-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nuevo sponsors</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Seleccione edificios">
              <v-select
                multiple
                v-model="data.building_uuids"
                :options="Buildins"
                :reduce="(value) => value.code"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Seleccione la empresa">
              <b-row>
                <b-col cols="auto" class="my-3" style="max-width: 44%">
                  <b-button
                    variant="primary"
                    class="btn-ghost"
                    @click="companyRoute"
                  >
                    Añadir nueva empresa
                  </b-button>
                </b-col>
                <b-col>
                  <b-form-select
                    style="margin-top: 17px"
                    v-model="data.company_uuid"
                    :options="Companies"
                  ></b-form-select>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input v-model="data.name" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="URL">
              <b-form-input v-model="data.url" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Válido hasta">
              <imask-input
                class="form-control"
                :value="data.until_at"
                v-imask="maskDob"
                :unmask="true"
                @complete="onCompleteUntilAt"
                placeholder="día/mes/año"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Imagen">
              <b-form-file
                placeholder="Seleccione una imagen"
                @change="uploadFile"
                accept=".jpg, .png, .gif"
                ref="file-input"
              ></b-form-file>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <span class="hp-caption text-black-80 hp-text-color-dark-30 pr-8">
              Activo
            </span>

            <b-form-checkbox
              switch
              size="lg"
              v-model="data.active"
              value="true"
            ></b-form-checkbox>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="created">
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- edit -->
    <b-modal
      size="xl"
      id="modal-edit-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-edit-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Editar Sponsor</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Seleccione edificios">
              <v-select
                multiple
                v-model="data.building_uuids"
                :options="Buildins"
                :reduce="(value) => value.code"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Seleccione empresa">
              <b-form-select
                v-model="data.company_uuid.uuid"
                :options="Companies"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input v-model="data.name" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="URL">
              <b-form-input v-model="data.url" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Válido hasta">
              <imask-input
                class="form-control"
                :value="data.until_ats"
                v-imask="maskDob"
                :unmask="true"
                @complete="onCompleteUntilAt"
                placeholder="día/mes/año"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Imagen">
              <b-form-file
                placeholder="Seleccione una imagen"
                @change="uploadFile"
                accept=".jpg, .png, .gif"
                ref="file-input"
              ></b-form-file>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <span class="hp-caption text-black-80 hp-text-color-dark-30 pr-8">
              Activo
            </span>

            <b-form-checkbox
              switch
              size="lg"
              v-model="data.active"
              value="true"
            ></b-form-checkbox>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="editd"> Editar </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- view -->
    <b-modal
      size="xl"
      id="modal-show-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-show-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Detalles del Sponsors</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <!-- <b-col cols="12">
                <b-form-group label="Seleccione edificios">
                  <b-form-select multiple v-model="data.building_uuids_shows" :options="Buildins" :select-size="4" disabled="true"></b-form-select>
                </b-form-group>
            </b-col> -->

          <b-col class="mt-8" cols="12">
            <span class="bold_text">Empresa : </span>
            <span> {{ show_data.company_uuid.name }} </span>
            <!-- <b-form-group label="Seleccione la empresa">
                <b-form-select v-model="show_data.company_uuid.uuid" :options="Companies" :disabled="true" ></b-form-select>
              </b-form-group> -->
          </b-col>

          <b-col class="mt-8" cols="12">
            <span class="bold_text">Nombre : </span>
            <span> {{ show_data.name }} </span>
            <!-- <b-form-group label="Nombre">
              <b-form-input v-model="show_data.name" type="text" :disabled="true"></b-form-input>
            </b-form-group> -->
          </b-col>

          <b-col class="mt-8" cols="12">
            <span class="bold_text">URL : </span>
            <span>
              <a v-bind:href="show_data.url" target="blank_">
                {{ show_data.url }}
              </a>
            </span>
            <!-- <b-form-group label="Url">
              <b-form-input v-model="show_data.url" type="text" :disabled="true"></b-form-input>
            </b-form-group> -->
          </b-col>

          <b-col class="mt-8" cols="12">
            <span class="bold_text">Válido hasta : </span>
            <span> {{ show_data.until_at }} </span>
            <!-- <b-form-group label="Valido hasta">
 
              <imask-input 
                class="form-control"
                :value="show_data.until_at"
                v-imask="maskDob"
                :unmask="true"
                @complete="onCompleteUntilAt"
                placeholder='día/mes/año'
                :disabled="true"
              />
            </b-form-group> -->
          </b-col>

          <b-col class="mt-8" cols="12">
            <span class="bold_text">Patrocinador activo : </span>
            <span> {{ !!show_data.active == true ? "si" : "no" }} </span>
            <!-- <b-form-group label="Valido hasta">
 
              <imask-input 
                class="form-control"
                :value="show_data.until_at"
                v-imask="maskDob"
                :unmask="true"
                @complete="onCompleteUntilAt"
                placeholder='día/mes/año'
                :disabled="true"
              />
            </b-form-group> -->
          </b-col>

          <b-col class="mt-8" cols="12">
            <img v-bind:src="show_data.show_img" />
          </b-col>

          <b-col cols="12 pl-12">
            <br />
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-show-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
} from "bootstrap-vue";

import datatable from "@/layouts/components/datatable/Datatable.vue";
import sweet from "@/logic/functions/sweetAlert";
import sponsors from "@/logic/functions/banners";
import company from "@/logic/functions/company";
import buildings from "@/logic/functions/buildings";
import { DateTime } from "luxon";
import { IMaskComponent, IMaskDirective } from "vue-imask";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, //this
    BFormFile,
    BPagination,
    datatable,
    vSelect,
    "imask-input": IMaskComponent,
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      fields: [
        {
          key: "company_uuid",
          label: "Nombre",
          formatter: (value, key, item) => {
            return item.name;
          },
        },
        {
          key: "company_uuid",
          label: "empresa",
          formatter: (value, key, item) => {
            return item.company_uuid.name;
          },
        },
        { key: "url", label: "Url" },
        {
          key: "until_at",
          label: "Válido hasta",
          formatter: (value, key, item) => {
            return DateTime.fromISO(item.until_at).toFormat("dd/MM/yyyy");
          },
        },
        { key: "actions", label: "Acciones" },
      ],
      maskDob: {
        mask: "00/00/0000",
        lazy: false,
      },
      items: [],
      Buildins: [],
      Companies: [],
      show_data: {
        company_uuid: {},
        building_uuids: [],
        building_uuids_show: [],
        building_uuids_shows: [],
      },
      data: {
        company_uuid: {},
        building_uuids: [],
        building_uuids_show: [],
        building_uuids_shows: [],
      },
      fileUpload: {},
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
    };
  },
  methods: {
    companyRoute() {
      this.$router.push("/admin/configuracion/edificios/sponsors/company");
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1;

      this.sponsors(page);
    },
    onCompleteUntilAt(e) {
      this.data.until_at = e.detail._value;
      this.data.until_ats = e.detail._value;
    },
    uploadFile(event) {
      this.fileUpload.file = event.target.files[0];
    },
    showBuildings(uuid) {
      sweet.ToastMsg("", "success", "Próximamente");
    },
    show(item) {
      this.show_data = JSON.parse(JSON.stringify(item));

      this.show_data.building_uuids_show = this.show_data.company_uuid.uuid;
      this.show_data.building_uuids_shows = this.show_data.company_uuid.uuid;

      this.show_data.show_img = this.show_data.image.path;

      this.show_data.until_at = DateTime.fromFormat(
        this.show_data.until_at,
        "yyyy-LL-dd"
      ).toFormat("dd/LL/yyyy");

      this.$bvModal.show("modal-show-document-admin");
    },
    edit(uuid) {
      let buldings = [];

      this.data = {};
      this.data = this.items.find((item) => {
        return item.uuid == uuid;
      });

      this.data.until_ats = DateTime.fromFormat(
        this.data.until_at,
        "yyyy-LL-dd"
      ).toFormat("dd/LL/yyyy");

      Object.entries(this.data.buildings).forEach((entry) => {
        const [key, value] = entry;
        buldings.push({ code: value.uuid, label: value.name });
      });

      this.data.building_uuids = buldings;

      this.$bvModal.show("modal-edit-document-admin");
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deletes(this.itemToDelete);
        this.$bvModal.hide("modal-delete-confirmation");
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid;
      this.$bvModal.show("modal-delete-confirmation");
    },
    deletes(uuid) {
      let items = this.items;
      sponsors
        .delete(uuid)
        .then((response) => {
          const index = items.findIndex((item) => item.uuid === uuid);
          if (~index) {
            items.splice(index, 1);
          }
          sweet.ToastMsg("", "success", "Patrocinador eliminado");
        })
        .catch((error) => {});
    },
    async created() {
      const params_data = `?company_uuid=${this.data.company_uuid}&active=${
        this.data.active
      }&name=${this.data.name}&url=${
        this.data.url
      }&until_at=${DateTime.fromFormat(
        this.data.until_at,
        "dd/LL/yyyy"
      ).toFormat("yyyy-LL-dd")}&comment=images`;
      const file = new FormData();

      file.append("building_uuids", this.data.building_uuids);
      file.append("file", this.fileUpload.file);

      await sponsors
        .create(file, params_data)
        .then((response) => {
          this.sponsors();
          sweet.ToastMsg("", "success", "Sponsors creado");
          this.$bvModal.hide("modal-add-document-admin");
        })
        .catch((error) => {});
    },
    async editd() {
      const params_data = `?company_uuid=${
        this.data.company_uuid.uuid
      }&active=${this.data.active}&name=${this.data.name}&url=${
        this.data.url
      }&until_at=${DateTime.fromFormat(
        this.data.until_ats,
        "dd/LL/yyyy"
      ).toFormat("yyyy-LL-dd")}&comment=images`;
      const file = new FormData();

      file.append("file", this.fileUpload.file);
      file.append("building_uuids", this.data.building_uuids);

      await sponsors
        .update(this.data.uuid, params_data, file)
        .then((response) => {
          this.sponsors();
          sweet.ToastMsg("", "success", "Sponsors editado");
          this.$bvModal.hide("modal-edit-document-admin");
        })
        .catch((error) => {});
    },
    async sponsors(page = 0) {
      await sponsors
        .list({
          params: {
            limit: 5,
            page: 0,
          },
        })
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {});
    },

    async companies(page = 0) {
      await company
        .list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.Companies.push({ value: value.uuid, text: value.name });
          });
        })
        .catch((error) => {});
    },
    async buildingsList(page = 0) {
      await buildings
        .list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.Buildins.push({ code: value.uuid, label: value.name });
          });
        })
        .catch((error) => {});
    },
  },
  beforeMount() {},
  mounted() {
    this.sponsors();
    this.companies();
    this.buildingsList();
  },
};
</script>
